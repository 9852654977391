import { useField, useFormFieldGroup } from "@/components/FormBuilder/Helpers";
import { FormBlockFieldConditionsV2 } from "@/components/FormBuilder/types";
export const specialQuestions: FormBlockFieldConditionsV2 = {
  or: [
    // {
    //   and: [
    //     {
    //       field: "dwellingType.answer",
    //       value: "Yes",
    //       operator: "equals"
    //     },
    //     {
    //       or: [
    //         {
    //           and: [
    //             {
    //               field: "companyNumber",
    //               value: 29,
    //               operator: "not"
    //             },
    //             {
    //               field: "companyNumber",
    //               value: 30,
    //               operator: "not"
    //             }
    //           ],
    //           or: [
    //             {
    //               field: "companyNumber",
    //               value: 26,
    //               operator: "not"
    //             },
    //             {
    //               field: "policyType",
    //               value: "T",
    //               operator: "not"
    //             }
    //           ]
    //         }
    //       ]
    //     }
    //   ]
    // },
    {
      and: [
        {
          field: "structureUnderConstruction.answer",
          value: "Yes",
          operator: "equals"
        },
        {
          or: [
            {
              field: "companyNumber",
              value: 26,
              operator: "not"
            },
            {
              field: "policyType",
              value: "T",
              operator: "not"
            }
          ]
        }
      ]
    },
    {
      field: "houseIsVacant.answer",
      value: "Yes",
      operator: "equals"
    }
  ]
};

export const approvalQuestions = [
  ...useFormFieldGroup(
    [
      useField({
        type: "checkbox",
        key: "hasUnderwriterApproved",
        label: "Has Underwriter approved all questionable answers?",
        required: true
      }),
      useField({
        type: "text",
        key: "underwritingResponses.underWriterApprovalCode",
        label: "Underwriter Approval Code",
        required: true,
        conditions: {
          and: [
            {
              operator: "equals",
              field: "hasUnderwriterApproved",
              value: true
            }
          ]
        }
      })
    ],
    { layout: "2-col" }
  )
];
